import React from 'react'
import Seo from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = () => {

  return(
    <Layout classmain='none'>
      <Seo title="404" description = "Something is not right with this webpage, please try again or go try a different webpage." />

      <div className='none__hero'>
        <div className='none__hero-img'>
        </div>
        <h2 className='none__hero-title htitle'>404</h2>

        <p className='none__hero-descr'>Oops… seems like you got lost in space</p>
        <div className='none__hero-buttons'>
          <a className="btn btn--v1" href="/">
              Homepage
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage